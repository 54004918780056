import JsPDF from 'jspdf';
import {alphaBlend, parseColor} from '../utils';
import {registerGlobalStyle} from '../theme';
import {uniq} from 'lodash';
import RubikBold from '../assets/rubik-bold.json';
import RubikNormal from '../assets/rubik-normal.json';

/**
 * We use jsPDF to generate the PDF, we manually add text, borders, fills, images, and fonts.
 *
 * Documentation for jsPDF is here: https://artskydj.github.io/jsPDF/docs/index.html
 *
 * Documentation for generating fonts is here: https://www.devlinpeck.com/content/jspdf-custom-font
 */

// Class names to be put on elements to control PDF rendering
export const PDF_IGNORE = 'pdf-ignore';
export const PDF_VIEW_HIDDEN = 'pdf-view-hidden';
export const PDF_NO_BREAK = 'pdf-no-break';
export const PDF_BREAK_CONTAINER = 'pdf-break-container';
export const PDF_REPEAT_OVER_PAGE_BREAK = 'pdf-repeat-over-page-break';

// Class names used internally
export const PDF_VIEW = 'pdf-view';
export const PDF_PAGE_BREAK = 'pdf-page-break';
export const PDF_PAGE_BREAK_BEFORE = 'pdf-page-break-before';

registerGlobalStyle('.pdf-view', () => ({
  position: 'fixed',
  border: 'none !important',
  '*': {backgroundColor: 'white',},
  '.pdf-view-hidden': {display: 'none'},

  // This hides everything after an element that is marked as a page break
  '.pdf-page-break': {display: 'none'},
  '.pdf-page-break ~ *': {display: 'none'},
  '*:has(.pdf-page-break) ~ *': {display: 'none'},

  '.pdf-break-container *:has(~.pdf-page-break-before)': {display: 'none'},
  '.pdf-break-container *:has(~* .pdf-page-break-before):not(table.pdf-repeat-over-page-break>thead)': {display: 'none'},
}));

const A4 = {width: 210, height: 297, xMargin: 15, yMargin: 15};
const MM_PT_SCALE = 2.8346456693;
const LINE_SCALE = 1;

export class PdfPrintService {
  static async print(rootElement) {
    try {
      rootElement.classList.add(PDF_VIEW);

      // We have changed the classes, and possibly just loaded the page, wait a moment for everything
      // to stabilise and then wait for the images to load
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await PdfPrintService.#awaitLoad(rootElement);

      const printer = new PdfPrintService(rootElement);

      // Sometimes the images fail to load, let's try a few time before we give up entirely
      for (let i = 1; i <= 1; ++i) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await printer.#printElement(rootElement);
          return printer;
        } catch (e) {
          console.info(`Printing PDF, attempt ${i} failed, try again`, e);
          // Just ignore this one, we will try again
        }
      }
    } finally {
      rootElement.classList.remove(PDF_VIEW);
      PdfPrintService.#cleanStyles(rootElement);
    }
    return null;
  }

  pdf;
  pxScale;
  origin;

  constructor(rootElement) {
    this.pdf = new JsPDF({unit: 'mm', format: 'a4'});
    this.paper = A4;

    const rect = rootElement.getBoundingClientRect();
    const style = window.getComputedStyle(rootElement);
    const offsetX = Number.parseFloat(style.paddingLeft) + Number.parseFloat(style.borderLeftWidth);
    const offsetY = Number.parseFloat(style.paddingLeft) + Number.parseFloat(style.borderLeftWidth);

    const innerWidth = rect.width - 2 * offsetX;
    this.pxScale = (this.paper.width - 2 * this.paper.xMargin) / innerWidth;

    this.origin = {
      x: (rect.left + offsetX) * this.pxScale - this.paper.xMargin,
      y: (rect.top + offsetY) * this.pxScale - this.paper.yMargin,
      yMax: this.paper.height - this.paper.yMargin,
    };

    this.ptScale = this.pxScale * MM_PT_SCALE;

    this.pdf.addFileToVFS('rubik-normal.ttf', RubikNormal.font);
    this.pdf.addFileToVFS('rubik-bold.ttf', RubikBold.font);
    this.pdf.addFont('rubik-normal.ttf', 'Rubik', 'normal');
    this.pdf.addFont('rubik-bold.ttf', 'Rubik', 'bold');
  }

  async save(fileName) {
    return this.pdf.save(fileName);
  }

  async #printElement(element){
    const style = window.getComputedStyle(element, null);
    if (element.classList.contains(PDF_IGNORE)
      || style.display === 'none'
      || style.visibility !== 'visible'
      || style.opacity === '0'
      || element.tagName === 'BUTTON'
    ) {
      // Just skip this element and it's children
      return;
    }

    // If this is a break container, check if it spans pages, in which case we will break the page
    if (element.classList.contains(PDF_BREAK_CONTAINER)) {
      const breakElem = this.#findPageBreak(element);
      if (breakElem) {
        // Print everything before the break element
        breakElem.classList.add(PDF_PAGE_BREAK);
        await this.#printElement(element);

        // Hide everything in the break container that is before the break, insert a page
        // then continue printing.
        breakElem.classList.remove(PDF_PAGE_BREAK);
        breakElem.classList.add(PDF_PAGE_BREAK_BEFORE);
        this.#insertPageBreak(element);
      }
    }

    this.#checkPageBreak(element);

    // Print borders and fills
    this.#printBorders(element, style);

    // Print any text nodes
    const childNodes = [...element.childNodes];
    if (childNodes.some((node) => node.nodeType === Node.TEXT_NODE && node.nodeValue.length > 0)) {
      if (!style.getPropertyValue('font-size').includes('px')) {
        throw new Error('Expected font size to be defined in pixels');
      }

      this.pdf.setFontSize(Number.parseFloat(style.getPropertyValue('font-size')) * this.ptScale);
      this.pdf.setFont('Rubik', Number.parseInt(style.fontWeight) > 400 ? 'bold' : 'normal');
      const [r, g, b] = parseColor(style.color);
      this.pdf.setTextColor(r, g, b);

      const textNodes = childNodes.filter((node) => node.nodeType === Node.TEXT_NODE && node.nodeValue.length > 0);
      if (textNodes.length > 0) {
        this.#printTextNodes(textNodes);
      }
    }

    switch (element.tagName) {
      case 'IMG':
        await this.#printImage(element);
        break;

      case 'CANVAS':
        await this.#printImage(element);
        break;

      default:
        break;
    }

    // Recurse into any child elements
    for await (const childElem of [...element.children]) {
      await this.#printElement(childElem);
    }
  }

  static #FILE_TYPES = {
    '.jpg': 'JPEG',
    '.jpeg': 'JPEG',
    '.png': 'PNG',
    '.tiff': 'TIFF',
    '.webp': 'WEBP',
  };

  async #printImage(element) {
    // Cross-origin images cannot be included in the PDF, so we will check if the image is cross-origin,
    // in which case we will download it again with anonymous cors, and if that fails we will then use
    // our backend API to download it.
    let img = element;
    if (this.#isTainted(element)) {
      // Load the image again using cross-origin tags
      img = await this.#loadImageCrossOrigin(element);
      if (!img) {
        // Load the image again from our back end proxy
        img = await this.#loadProxyImage(element);
      }
    }

    if (img) {
      const rect = element.getBoundingClientRect();
      try {
        this.pdf.addImage(img, this.#getX(rect.left), this.#getY(rect.top), this.#getWidth(rect.width), this.#getHeight(rect.height));
        return;
      } catch (err) {
        if (!err.message.includes('UNKNOWN')) {
          throw err;
        }
      }

      // The image could not be added because the PDF library can't figure out the file type. We will try known file types now.
      const url = img.src;
      const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
      const fileTypes = uniq([...Object.values(PdfPrintService.#FILE_TYPES)]);
      const index = fileTypes.indexOf(PdfPrintService.#FILE_TYPES[extension]);
      if (index >= 0) {
        // If a file type was found we will do it first
        fileTypes.splice(0, 0, fileTypes[index]);
        fileTypes.splice(index + 1, 1);
      }
      for (const fileType of fileTypes) {
        try {
          this.pdf.addImage(img, fileType, this.#getX(rect.left), this.#getY(rect.top), this.#getWidth(rect.width), this.#getHeight(rect.height));
          return;
        } catch {
          // Nothing to do here
        }
      }

      // Still couldn't add the image, try the placeholder
      img = await this.#loadImageCrossOrigin({src: 'https://app-hoops-upload-production.s3.ap-southeast-2.amazonaws.com/product-placeholder-image.jpg'});
      if (img) {
        try {
          this.pdf.addImage(img, 'JPEG', this.#getX(rect.left), this.#getY(rect.top), this.#getWidth(rect.width), this.#getHeight(rect.height));
          return;
        } catch (err) {
          // Nothing to do here
        }
      }
      console.log('Unable to load the image, skipping: ', element.src);
    }
  }

  #isTainted(element) {
    try {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.drawImage(element, 0, 0);
      context.getImageData(0, 0, 1, 1);
    } catch {
      return true;
    }
    return false;
  }

  async #loadImageCrossOrigin(element) {
    console.info('Attempt cross origin image load: ', element.src);
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => resolve(null);
      img.crossOrigin = 'anonymous';
      img.src = element.src;
    });
  }

  async #loadProxyImage(element) {
    console.info('Attempt proxy image load: ', element.src);
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => resolve(null);
      img.crossOrigin = 'anonymous';
      img.src = `${process.env.REACT_APP_BASE_URL}/rest/image/fetch?url=${encodeURIComponent(element.src)}`;
    });
  }

  #printBorders(element, style) {
    if (style.borderWidth && style.borderWidth !== '0px') {
      const rect = element.getBoundingClientRect();
      const x0 = this.#getX(rect.x);
      const w = this.#getWidth(rect.width);
      const x1 = x0 + w;
      const y0 = this.#getY(rect.y);
      const h = this.#getHeight(rect.height);
      const y1 = y0 + h;
      const thicknesses = style.borderWidth.split(' ').map((s) => Number.parseFloat(s));
      let backgroundColor = alphaBlend(style.backgroundColor, '#FFFFFF', '#');
      if (backgroundColor === '#000000') {
        backgroundColor = '#FFFFFF';
      }
      if (thicknesses.length === 1) {
        this.pdf.setDrawColor(alphaBlend(style.borderColor, style.backgroundColor || '#FFFFFF', '#'));
        this.pdf.setLineWidth(this.pxScale * thicknesses[0] * LINE_SCALE);
        this.pdf.setFillColor(backgroundColor);
        const borderRadius = Number.parseFloat(style.borderRadius);
        if (borderRadius > 0) {
          const r = borderRadius * this.pxScale;
          this.pdf.roundedRect(x0, y0, w, h, r, r, backgroundColor === '#ffffff' ? 'S' : 'DF');
        } else {
          this.pdf.rect(x0, y0, w, h,  backgroundColor === '#ffffff' ? 'S' : 'DF');
        }
      } else {
        if (backgroundColor !== '#ffffff') {
          this.pdf.setLineWidth(0);
          this.pdf.setFillColor(backgroundColor);
          this.pdf.rect(x0, y0, w, h, 'F');
        }
        if (thicknesses[0] > 0 || thicknesses[2] > 0) {
          if (thicknesses[0] > 0) {
            this.pdf.setDrawColor(alphaBlend(style.borderTopColor, style.backgroundColor, '#'));
            this.pdf.setLineWidth(this.pxScale * thicknesses[0] * LINE_SCALE);
            this.pdf.line(x0, y0, x1, y0, 'S');
          }
          if ((thicknesses[2] ?? thicknesses[0]) > 0) {
            this.pdf.setDrawColor(alphaBlend(style.borderBottomColor, style.backgroundColor, '#'));
            this.pdf.setLineWidth(this.pxScale * (thicknesses[2] ?? thicknesses[0]) * LINE_SCALE);
            this.pdf.line(x0, y1, x1, y1, 'S');
          }
        }
        if (thicknesses[1] > 0 || thicknesses[3] > 0) {
          if (thicknesses[1] > 0) {
            this.pdf.setDrawColor(alphaBlend(style.borderRightColor, style.backgroundColor, '#'));
            this.pdf.setLineWidth(this.pxScale * thicknesses[1] * LINE_SCALE);
            this.pdf.line(x1, y0, x1, y1, 'S');
          }
          if ((thicknesses[3] ?? thicknesses[1]) > 0) {
            this.pdf.setDrawColor(alphaBlend(style.borderLeftColor, style.backgroundColor, '#'));
            this.pdf.setLineWidth(this.pxScale * (thicknesses[3] ?? thicknesses[1]) * LINE_SCALE);
            this.pdf.line(x0, y1, x1, y1, 'S');
          }
        }
      }
    }
  }

  #printTextNodes(nodes) {
    nodes.forEach((node) => {
      // So, the browser has laid the text out for us, with all the fancy stuff it does, so we will
      // go through the text node one character at a time, finding each line, and print each line at
      // the position of the line.
      const range = new Range();
      range.selectNodeContents(node);
      switch (range.getClientRects().length) {
        case 0:
          // There is no actual text, just skip it
          break;

        case 1: {
          // A single line of text, skip the iteration and just print it
          const rect = range.getBoundingClientRect();
          this.pdf.text(node.nodeValue, this.#getX(rect.left), this.#getY(rect.top), {baseline: 'top'});
          break;
        }

        default: {
          // Multiple lines, we have to find each break
          range.setStart(node, 0);
          for (let charPos = 0, startPos = 0; charPos < node.nodeValue.length; charPos += 1) {
            range.setEnd(node, charPos + 1);
            const rects = range.getClientRects();
            if (rects.length > 1 || charPos + 1 >= node.nodeValue.length) {
              // Found the line break (or the end of the text), print the text preceding it
              const length = charPos + 1 < node.nodeValue.length ? charPos - startPos : charPos - startPos + 1;
              this.pdf.text(node.substringData(startPos, length), this.#getX(rects[0].left), this.#getY(rects[0].top), {baseline: 'top'});
              startPos = charPos;
              range.setStart(node, startPos);
            }
          }
        }
      }
    });
  }

  #findPageBreak(element, containerElement) {
    if (!containerElement) {
      const rect = element.getBoundingClientRect();
      if (this.#getY(rect.bottom) <= this.origin.yMax) {
        return null;
      }
      containerElement = element;
    }

    if (!element.classList.contains(PDF_NO_BREAK)) {
      for (const childElem of [...element.children].reverse()) {
        let breakElem = this.#findPageBreak(childElem, containerElement);
        if (breakElem) {
          const tr = breakElem.closest('tr');
          if (tr) {
            breakElem = tr;
          }
          return breakElem;
        }
      }
    }

    if (element !== containerElement) {
      element.classList.add(PDF_PAGE_BREAK);
      const rect = containerElement.getBoundingClientRect();
      element.classList.remove(PDF_PAGE_BREAK);
      if (this.#getY(rect.bottom) <= this.origin.yMax) {
        return element;
      }
    }

    return null;
  }

  #checkPageBreak(element) {
    // Put a page break in if the top of the element is off the page, or it can't be broken and
    // the bottom is off the page.
    const rect = element.getBoundingClientRect();
    if (this.#getY(rect.top) > this.origin.yMax) {
      this.#insertPageBreak(element);
    } else if (element.classList.contains(PDF_NO_BREAK) && this.#getY(rect.bottom) > this.origin.yMax) {
      this.#insertPageBreak(element);
    }
  }

  #insertPageBreak(element) {
    const rect = element.getBoundingClientRect();
    this.pdf.addPage();
    this.origin.y = rect.top * this.pxScale - this.paper.yMargin;
  }

  #getX(x) {
    return x * this.pxScale - this.origin.x;
  }

  #getY(y) {
    return y * this.pxScale - this.origin.y;
  }

  #getWidth(width) {
    return width * this.pxScale;
  }

  #getHeight(height) {
    return height * this.pxScale;
  }

  static #cleanStyles(element) {
    element.classList.remove(PDF_PAGE_BREAK);
    element.classList.remove(PDF_PAGE_BREAK_BEFORE);

    for (const childElem of [...element.children]) {
      this.#cleanStyles(childElem);
    }
  }

  static async #awaitLoad(element) {
    // When we print the PDF, the page to print may have just been loaded, so let's make sure all
    // the images have been loaded before we start
    await Promise.all([...element.children].map((child) => PdfPrintService.#awaitLoad(child)));

    if (element.tagName === 'IMG' && !element.complete) {
      await new Promise((resolve) => {
        function loadComplete() {
          element.onload = null;
          element.onerror = null;
          resolve();
        }
        element.onload = loadComplete;
        element.onerror = loadComplete;
      });
    }
  }
}
