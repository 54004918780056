import {useMutation, useQuery, useQueryClient} from 'react-query';
import axios from 'axios';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {stringify} from 'qs';
import {camelCase} from 'lodash';
import {GENERAL_ERROR_MESSAGE, useDebounceState, useSnackbar} from '.';
import {CacheMode} from './api';
import {useDeepObjectMemo} from './useDeepObjectMemo';
import {useSessionChanged} from '../componentsHoops/AccessControl/MultiSessionManager';

//
// We use react-query for the guts API and have customised it a little to neatly match our use cases. Full docs
// can be found here: https://tanstack.com/query/v3/docs/react/reference/useQuery
//

export function useGetApi(path, id, {params, cacheMode, throwOnError} = {}) {
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();

  const response = useQuery(
      [path, id, params],
      () => doFetch({method: 'get', path, id, options: {throwOnError}, params, snackbar, sessionChanged}),
      {
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: false,
        staleTime: cacheMode === CacheMode.Always ? Infinity : 0,
      }
  );

  let {data = emptyObject, isLoading, refetch: refetchQuery} = response;

  const refetch = useCallback(({throwOnError: _throwOnError} = {}) => refetchQuery({throwOnError: _throwOnError ?? throwOnError}), [refetchQuery, throwOnError]);

  if (cacheMode === CacheMode.Never && response.isFetching) {
    data = emptyObject;
    isLoading = true;
  }

  return useMemo(() => ({data, isLoading, refetch}), [data, isLoading, refetch]);
}

export function useApiCache(path, id = undefined, {params} = {}) {
  const queryClient = useQueryClient();
  const updateCache = useCallback((data, {params: _params} = {}) => {
    const updateId = id ?? data._id ?? data.id;
    if (queryClient.getQueryData([path, updateId, params])) {
      queryClient.setQueryData([path, updateId, params || _params ? {...params, ..._params} : undefined], data);
      return true;
    }
    return false;
  }, [queryClient, path, id, params]);
  return {updateCache};
}

export function useFetchApi(_path) {
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const queryClient = useQueryClient();

  const fetchQuery = useCallback((id, {params, cacheMode, throwOnError, ...more} = {}) => {
    let path = _path;
    if (typeof path === 'function') {
      path = path({id, ...more});
      id = null;
    }
    return queryClient.fetchQuery(
      [path, id, params],
      () => doFetch({method: 'get', path, id, options: {throwOnError}, params, snackbar, sessionChanged}),
      {
        retry: false,
        staleTime: cacheMode === CacheMode.Always ? Infinity : 0,
      }
    );
  }, [_path, queryClient, sessionChanged, snackbar]);

  return useMemo(() => ({fetch: fetchQuery}), [fetchQuery]);
}

export function useQueryApi(path) {
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);

  const fetchQuery = useCallback(async ({params, cacheMode, throwOnError} = {}) => {
    try {
      setLoading(true);
      return await queryClient.fetchQuery(
        [path, params],
        () => doFetch({method: 'get', path, options: {throwOnError}, params, snackbar, sessionChanged}),
        {
          retry: false,
          staleTime: cacheMode === CacheMode.Always ? Infinity : 0,
        }
      );
    } finally {
      setLoading(false);
    }
  }, [path, queryClient, sessionChanged, snackbar]);

  return useMemo(() => ({query: fetchQuery, isLoading}), [fetchQuery, isLoading]);
}

export function useListApi(path, {skip, limit, page, pageSize, sort, search, filters, query, ...more} = {}, {cacheMode, debounce, enabled, throwOnError} = {}) {
  const [params, setParams] = useDebounceState({enabled, skip, limit, page, pageSize, search, sort, filters, query, ...more}, debounce ?? 300);
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const response = useQuery(
      [path, params],
      () => doFetch({method: 'get', path, options: {throwOnError}, params, snackbar, sessionChanged}),
      {
        refetchOnWindowFocus: false,
        enabled: params.enabled ?? (params.search != null || params.query != null),
        retry: false,
        staleTime: cacheMode === CacheMode.Always ? Infinity : 0,
      }
  );

  useEffect(() => {
    setParams({enabled, skip, limit, page, pageSize, search, sort, filters, query, ...more});
  }, [enabled, skip, limit, page, pageSize, search, sort, filters, query, more, setParams]);

  let {data = emptyObject, isLoading, refetch: refetchQuery} = response;
  if (enabled === false || params.enabled === false) {
    data = emptyObject;
  }

  const refetch = useCallback(({throwOnError: _throwOnError} = {}) => refetchQuery({throwOnError: _throwOnError ?? throwOnError}), [refetchQuery, throwOnError]);

  const queryClient = useQueryClient();
  const updateCache = useCallback((cacheUpdate) => {
    queryClient.setQueryData([path, params], cacheUpdate);
  }, [queryClient, path, params]);

  return useMemo(() => ({data, isLoading, params, setParams, refetch, updateCache}), [data, isLoading, params, setParams, refetch, updateCache]);
}

// Replace or create: PUT
export function useSaveApi(path, _options) {
  _options = useDeepObjectMemo(_options);
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const {isLoading, mutateAsync} =
    useMutation(({id, data, options, params}) => doFetch({method: 'put', path, id, data, options, params, snackbar, sessionChanged}));

  const save = useCallback(async ({id, _id, ...data}, {params, ...options} = {}) =>
    await mutateAsync({id: id ?? _id, data, options: {..._options, ...options}, params}), [_options, mutateAsync]);

  return useMemo(() => ({isSaving: isLoading, save}), [isLoading, save]);
}

// Create: POST
export function useCreateApi(path, {updateCache, ..._options} = {}) {
  _options = useDeepObjectMemo(_options);
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const {isLoading, mutateAsync} =
    useMutation(({data, options, params}) => doFetch({method: 'post', path, data, options, params, snackbar, sessionChanged}));

  const create = useCallback(async ({...data}, {params, ...options} = {}) => {
    const res = await mutateAsync({data, options: {..._options, ...options}, params});
    if (res && updateCache) {
      updateCache(res);
    }
    return res;
  }, [_options, mutateAsync, updateCache]);

  return useMemo(() => ({isSaving: isLoading, create}), [create, isLoading]);
}

// Actions: POST
export function useActionApi(path, action, _options = {}) {
  _options = useDeepObjectMemo(_options);
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const {isLoading, mutateAsync} =
    useMutation(({id, data, options, params}) =>
      doFetch({method: 'post', path, id, postPath: action, data, options, params, snackbar, sessionChanged}));

  const actionApi = useCallback(async ({id, ...data} = {}, {params, ...options} = {}) =>
    await mutateAsync({id, data, options: {..._options, ...options}, params}), [_options, mutateAsync]);

  return useMemo(() => ({isInProgress: isLoading, [action]: actionApi, [camelCase(action)]: actionApi}), [action, actionApi, isLoading]);
}

// Partial update: PATCH
export function useUpdateApi(path, {updateCache, ..._options} = {}) {
  _options = useDeepObjectMemo(_options);
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const {isLoading, mutateAsync} =
    useMutation(({id, data, options, params}) => doFetch({method: 'patch', path, id, data, options, params, snackbar, sessionChanged}));

  const update = useCallback(async ({id, ...data}, {params, ...options} = {}) => {
    const res = await mutateAsync({id, data, options: {..._options, ...options}, params});
    if (res && updateCache) {
      updateCache(res);
    }
    return res;
  }, [_options, mutateAsync, updateCache]);

  return useMemo(() => ({isSaving: isLoading, update}), [isLoading, update]);
}

// Delete: DELETE
export function useDeleteApi(path, {updateCache, ..._options} = {}) {
  _options = useDeepObjectMemo(_options);
  const snackbar = useSnackbar();
  const sessionChanged = useSessionChanged();
  const {isLoading, mutateAsync} = useMutation(({id, options}) => doFetch({method: 'delete', path, id, options, snackbar, sessionChanged}));

  const _delete = useCallback(async ({id}, options = {}) => {
    const res = await mutateAsync({id, options: {..._options, ...options}});
    if (res && updateCache) {
      updateCache(res);
    }
    return res;
  }, [_options, mutateAsync, updateCache]);

  return useMemo(() => ({isDeleting: isLoading, delete: _delete}), [_delete, isLoading]);
}

const emptyObject = {};

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/rest`,
  withCredentials: true
});

async function doFetch({method, path, postPath, id, data, options, params, snackbar, sessionChanged}) {
  let body;
  try {
    id = (options.id ?? id) ? `/${options.id ?? id}` : '';
    postPath = postPath ? `/${postPath}` : '';
    params = params ? `?${stringify(params, {encodeValuesOnly: true, strictNullHandling: true})}` : '';
    const response = await axiosClient({
      url: `${process.env.REACT_APP_BASE_URL}/rest/${path}${id}${postPath}${params}`,
      method: options.method ?? method,
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        ...(data ? {'Content-Type': 'application/json'} : {}),
      },
      ...(data ? {data} : {}),
    });

    body = response.headers['content-type']?.includes('application/json') ? response.data : null;
    if (response.status !== 200) {
      throw {status: response.status, message: response.message ?? body?.message ?? response.statusText, body};
    }

    if (response.headers['x-session-changed']) {
      // If the session changed header was received, notify the app
      sessionChanged?.();
    }

    if (options.successMessage) {
      const message = typeof options.successMessage === 'function'
          ? options.successMessage({method, path, id, params, data, body, status: response.status})
          : options.successMessage;
      snackbar.showSnackbarSuccess(message);
    }
    return body;
  } catch (error) {
    console.error('Error in API:', error);
    if (options.throwOnError) {
      throw error;
    }
    const message = typeof options.errorMessage === 'function'
      ? options.errorMessage({
        method,
        path,
        id,
        params,
        data,
        body: body ?? error.response.data,
        error,
        message: error.response?.data?.message,
        status: error.response.status,
      })
      : options.errorMessage;
    snackbar.showSnackbarError(message || GENERAL_ERROR_MESSAGE);
  }
  return method === 'get' ? {} : null;
}
