import {useCallback, useMemo, useState} from 'react';
import * as AWS from 'aws-sdk';
import * as uuidv4 from 'uuid/v4';
import {useSnackbar} from './useSnackbar';
import {asRounded} from '../utils';

// This is now the definitive was to connect to s3, future uses of S3 should import from here

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,})
});

export const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  endpoint: (process.env.REACT_APP_SERVERLESS_OFFLINE === 'true' ? 'http://localhost:4572' : undefined),
  s3ForcePathStyle: (process.env.REACT_APP_SERVERLESS_OFFLINE === 'true' ? true : undefined),
  params: {Region: process.env.REACT_APP_AWS_REGION}
});

export function useS3UploadCache() {
  const snack = useSnackbar();
  const [_files, setFiles] = useState({});
  const [progress, setProgress] = useState(100);

  const uploadFile = useCallback(async (file) => {
    const uuid = uuidv4();
    setFiles((prevFiles) => ({
      ...prevFiles,
      [uuid]: {name: file.name, size: file.size, type: file.type, loaded: 0, uuid}
    }));

    const upload = s3.upload({
      Key: uuid,
      Body: file,
      ACL: 'public-read',
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    });

    upload.on('httpUploadProgress', ({loaded}) => {
      setFiles((prevFiles) => {
        const newFiles = {...prevFiles};
        newFiles[uuid] = {...prevFiles[uuid], loaded};
        return newFiles;
      });

      const totals = Object.values(_files).reduce((acc, currFile) => {
        if (currFile.uuid !== uuid) {
          acc.loaded += currFile.size;
          acc.total += currFile.loaded;
        }
        return acc;
      }, {loaded, total: file.size});

      const percent = asRounded(totals.loaded / (totals.total || 1) * 100, 0);

      setProgress(percent);
    });

    try {
      const data = await upload.promise();
      setFiles((prevFiles) => {
        const newFiles = {...prevFiles};
        newFiles[uuid] = {...prevFiles[uuid], url: data.Location, loaded: file.size};
        return newFiles;
      });
      snack.showSnackbarSuccess(`Successfully uploaded ${file.name}`);
      return data.Location;
    } catch (err) {
      setFiles((prevFiles) => {
        const newFiles = {...prevFiles};
        delete newFiles[uuid];
        return newFiles;
      });
      snack.showSnackbarError(`Unable to upload ${file.name}: ${err.message}`);
    }
    return null;
  }, [_files, snack]);

  const deleteFile = useCallback(async (file) => {
    setFiles((prevFiles) => {
      const newFiles = {...prevFiles};
      delete newFiles[file.uuid];
      return newFiles;
    });
  }, []);

  return useMemo(() => ({
    files: Object.values(_files),
    urls: Object.values(_files).map((file) => file.url),
    progress,
    deleteFile,
    uploadFile,
  }), [_files, progress, deleteFile, uploadFile]);
}
