import * as React from 'react';
import {Column, Fields} from '../../../componentsLib/Layout';
import {useListSalesDocs} from '../../../hooks/api';
import {BusySpinner} from '../../../componentsLib/Basic';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {asCurrencyStringCommaSeparated, formatDateLong, fullName} from '../../../utils';
import {registerGlobalStyle} from '../../../theme';

registerGlobalStyle('.sales-store-details-tab', (theme) => ({
  '.fields': {
    padding: theme.spacing(1),
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

export function SalesStoreDetailsTab({salesStore}) {
  const {data: {salesDocs}, isLoading} = useListSalesDocs({query: {salesStoreId: salesStore._id}});

  return (
    <Column className={'sales-store-details-tab'} paper fillWidth pad gap>
      {isLoading &&
        <BusySpinner message={'Loading documents'} left/>
      }
      {salesDocs?.map((salesDoc) => (
        <Fields key={salesDoc.id} leftLabel>
          <CaptionText text={`${salesDoc.docTypeName} #`}/>
          <BodyText text={salesDoc.number}/>
          <CaptionText text={'Company'}/>
          <BodyText text={salesDoc.customer.name}/>
          <CaptionText text={'Contact'}/>
          <BodyText text={fullName(salesDoc.contact)}/>
          <CaptionText text={'Email'}/>
          <BodyText text={salesDoc.contact.email}/>
          <CaptionText text={'Date'}/>
          <BodyText>{formatDateLong(salesDoc.createdAt)}</BodyText>
          <CaptionText text={'Value (ex tax)'}/>
          <BodyText currency text={asCurrencyStringCommaSeparated(salesDoc.subTotal)}/>
        </Fields>
      ))}
    </Column>
  );
}
