import {useCallback, useMemo, useState} from 'react';
import {useCommentSalesDoc} from '../../../hooks/api';

export const useComment = ({id, successMessage, onSuccess}) => {
  const {comment: commentSalesDocAPI, isInProgress: savingComment} = useCommentSalesDoc();
  const [commentText, setCommentText] = useState('');

  const saveComment = useCallback(async () => {
    const res = await commentSalesDocAPI({id, commentText},
      {
        successMessage,
        errorMessage: ({message}) => message,
      }
    );
    if (res) {
      setCommentText('');
      onSuccess();
    }
  }, [id, commentSalesDocAPI, commentText, onSuccess, successMessage]);

  return useMemo(() => ({commentText, setCommentText, savingComment, saveComment}), [commentText, setCommentText, savingComment, saveComment]);
};

