import React, {useCallback, useState} from 'react';
import {Column, Fields, Row, Table, TableCell, TableRow} from '../../../componentsLib/Layout';
import {useFetchSalesDocHostingStatus, useGetSalesDocHostingStatus, useListSalesDocs} from '../../../hooks/api';
import {Button, Switch, TextInput} from '../../../componentsLib/Basic';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';

registerGlobalStyle('.sales-store-hosting-tab', (theme) => ({
  '.hosting-details': {
    '.text-input': {maxWidth: '40%'},
    '.text-body': {
      color: theme.colors.text.descriptive,
      lineHeight: 1.2,
    },
  },
}));

export function SalesStoreHostingTab({salesStore, updateField}) {
  const [enableHosting, setEnableHosting] = useState(!!salesStore.hosting?.domain);
  const [domain, setDomain] = useState(salesStore.hosting?.domain);
  const [path, setPath] = useState(salesStore.hosting?.path);
  const [isSaving, setIsSaving] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const {hostingStatus: checkStatusApi, isInProgress: isCheckingStatus} = useFetchSalesDocHostingStatus(salesStore._id);
  const [hostingStatus, setHostingStatus] = useState();

  const handleCheckStatus = useCallback(async () => {
    const res = await checkStatusApi();
    if (res) {
      setHostingStatus(res.hosting);
    }
  }, [checkStatusApi]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    await updateField({id: salesStore._id, field: 'hosting', value: {'hosting.domain': domain, 'hosting.path': path}});
    setIsSaving(false);
  }, [domain, path, salesStore, updateField]);

  // There are changes to save if they have turned off hosting, or if they have changed the domain or path and the domain is valid
  const canSave = (!enableHosting && salesStore.hosting?.domain)
    || (enableHosting && isChanged(domain, salesStore.hosting?.domain) && isValidDomainName(domain))
    || (enableHosting && isValidDomainName(domain) && isChanged(path, salesStore.hosting?.path) && isValidPath(path));

  return (
    <Column className={'sales-store-hosting-tab'} fillWidth gap>
      <Column className={'hosting-details'} paper fillWidth pad gap>
        <Row justifySpaceBetween>
          <Switch checked={enableHosting} label={'Enable Hosting'} onChange={(e) => setEnableHosting(e.target.checked)} />
          <Button navMinor text={'Check Status'} onClick={handleCheckStatus} disabled={!enableHosting} loading={isCheckingStatus} noWrap/>
        </Row>
        <Fields topLabel fillWidth>
          <CaptionText text={'Domain Name'}/>
          <Row gap alignCenter>
            <TextInput value={domain} onChange={(e) => setDomain(e.target.value)} disabled={!enableHosting}/>
            <BodyText>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</BodyText>
          </Row>
          <CaptionText text={'Path'}/>
          <Row gap alignCenter>
            <TextInput value={path} onChange={(e) => setPath(e.target.value)} disabled={!enableHosting}/>
            <BodyText>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</BodyText>
          </Row>
        </Fields>
        <Button text={'Save Changes'} navMain onClick={handleSave} disabled={!canSave} loading={isSaving} noWrap/>
      </Column>

      {hostingStatus?.validations?.length > 0 &&
        <Column paper fillWidth pad gap>
          <BodyText>The following DNS entries must be added to the appropriate DNS service</BodyText>
          <Table labels={['Domain Name', 'CNAME Record Name', 'CNAME Record Value']}>
            {hostingStatus.validations.map((validation) => (
              <TableRow key={validation.domain}>
                <TableCell>{validation.domain}</TableCell>
                <TableCell>{validation.name}</TableCell>
                <TableCell>{validation.value}</TableCell>
              </TableRow>
            ))}
          </Table>
          <BodyText>
            Once the DNS records are created they must be left in place as long as the SalesStore is being hosted. Removing the DNS records
            may prevent hosting additional SalesStores.
          </BodyText>
        </Column>
      }
    </Column>
  );
}

function isChanged(value1, value2) {
  return value1 !== value2 || !!value1 !== !!value2;
}

function isValidDomainName(domainName) {
  // TODO: Validate the domain name
  return !!domainName;
}

function isValidPath(path) {
  // TODO: Validate the path
  return !!path;
}

