import React from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import PropTypes from 'prop-types';

registerGlobalStyle('.box-grid', (theme) => ({
  display: 'grid',
  alignItems: 'baseline',
  rowGap: theme.spacing(1),
  columnGap: theme.spacing(1),
  alignSelf: 'stretch',
  justifyItems: 'start',
  '.grid-span': {width: '100%'},
}));

export function Grid({className, gridTemplateColumns, gridTemplateRows, children, ...props}) {
  return <div className={classNames([className, 'box-grid'])} style={{gridTemplateColumns, gridTemplateRows}} {...props}>{children}</div>;
}

Grid.propTypes = {
  className: HoopsPropTypes.className,
  gridTemplateColumns: PropTypes.string,
  gridTemplateRows: PropTypes.string,
  children: HoopsPropTypes.children,
};

export function GridSpan({className, gridColumn, gridRow, children}) {
  return (
    <div className={classNames([className, 'grid-span'])} style={{gridColumn, gridRow}}>
      {children}
    </div>
  );
}

GridSpan.propTypes = {
  className: HoopsPropTypes.className,
  gridColumn: PropTypes.string,
  gridRow: PropTypes.string,
  children: HoopsPropTypes.children,
};
