import React, {forwardRef, useCallback, isValidElement, useRef, Fragment} from 'react';
import {Close as ClearIcon} from '@mui/icons-material';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {CaptionText} from '../Text';
import {useMountEffect} from '../../hooks';
import {Field} from '../Layout';

registerGlobalStyle('.text-input', (theme) => ({
  transition: theme.transitions.out.all,
  '&:hover': {transition: theme.transitions.in.all,},
  '&:is(input)::-webkit-outer-spin-button, &:is(input)::-webkit-inner-spin-button, input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    // This disables the spinners -- spinners are always disabled
    display: 'none',
    'WebkitAppearance': 'none',
    margin: 0,
  },
  // Following are commented out in case we want to enable spinners in some cases
  // '&:is(input[type="number"]), &.number-input': {paddingRight: 2}, // This will allow space for the spinner
  // '&.number-input::after': {
  //   // This positions the suffix with a little gap between it and the spinner
  //   right: 0,
  //   paddingRight: 4,
  //   paddingLeft: 2,
  // },
  '&.container': {display: 'flex',},
  '&.disabled, &.disabled *': {pointerEvents: 'none',},
  '&:is(input), input, &:is(textarea), textarea': {
    width: '100%',
    '&::placeholder': {color: theme.colors.text.mediumDecorator,},
    '&:focus::placeholder': {color: theme.colors.text.light},
  },
  'input, textarea': {backgroundColor: 'inherit'},
  '&:is(textarea), textarea': {resize: 'none',},
  '&.disabled': {backgroundColor: theme.colors.background.disabled,},
  '&::before, &::after': {
    position: 'relative',
    height: 0,
    color: theme.colors.text.medium,
    lineHeight: 'normal',
  },
  '&::before': {left: '-8px'},
  '&::after': {marginRight: '-8px'},
  '.clearable-icon': {
    display: 'flex',
    alignSelf: 'center',
    position: 'relative',
    right: theme.spacing(.5),
    width: 0,
    color: theme.colors.text.dark,
    opacity: 0,
    transition: theme.transitions.in.all,
    backgroundColor: 'inherit',
    zIndex: 1,
    pointerEvents: 'none',
    cursor: 'pointer',
    '&>svg': {
      fontSize: 'inherit',
      backgroundColor: 'inherit',
    },
  },
  '&:hover .clearable-icon, &:focus-within .clearable-icon': {
    opacity: 1,
    transition: theme.transitions.in.all,
    pointerEvents: 'unset'
  },
  '&.auto-size': {
    position: 'relative',
    span: {
      visibility: 'hidden',
      pointerEvents: 'none',
      zIndex: -1,
      minWidth: 2,
    },
    input: {
      position: 'absolute',
      left: 0,
      paddingInline: 'inherit',
      backgroundColor: 'transparent',
    },
    '&::after': {left: theme.spacing(.5),}
  },
  '&:invalid, &.invalid': {backgroundColor: theme.colors.background.invalidBackground,}
}));

export const TextInput = forwardRef(
function TextInput({
  className,
  autoComplete,
  autoFocus,
  autoSize,
  blurOnEnterEscape,
  clearable,
  container,
  disabled,
  field,
  inputRef,
  label,
  multiline,
  rows,
  name,
  placeholder,
  prefix,
  readOnly,
  required,
  selectOnFocus,
  step,
  suffix,
  tabIndex,
  trim = true,
  type = 'text',
  value,
  onBlur,
  onChange,
  onClear,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  onMouseDown,
},
  ref)
{
  const _inputRef = useRef();
  if (!inputRef) {
    inputRef = _inputRef;
  }

  const handleClick = useCallback((event) => {
    const input = event.target.querySelector('input');
    if (input) {
      input.focus();
    }
    onClick?.(event);
  }, [onClick]);

  const handleFocus = useCallback((e) => {
    onFocus?.(e);
    if (selectOnFocus) {
      setTimeout(() => inputRef.current?.select(), 0);
    }
  }, [inputRef, onFocus, selectOnFocus]);

  const handleBlur = useCallback((e) => {
    if (trim) {
      e.target.value = e.target.value.trim();
      onChange(e);
    }
    onBlur?.(e);
  }, [onBlur, onChange, trim]);

  const handleKeyDown = useCallback((e) => {
    if (blurOnEnterEscape) {
      switch (e.code) {
        case 'Enter':
        case 'Escape':
          if (inputRef.current) {
            inputRef.current.blur();
          }
      }
    }
    onKeyDown?.(e);
  }, [blurOnEnterEscape, inputRef, onKeyDown]);

  if (suffix && !isValidElement(suffix)) {
    const E = suffix;
    suffix = <E />;
  }

  useMountEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  });

  const Tag = multiline ? 'textarea' : 'input';
  const Container = field ? Field : Fragment;

  return (
    <Container>
      {label && <CaptionText>{label}</CaptionText>}
      {(container || prefix || suffix || clearable || autoSize) &&
        <div
          className={classNames([
            className,
            'text-input',
            'input-outline',
            'container',
            autoSize && 'auto-size',
            clearable && 'clearable',
            disabled && 'disabled',
            !disabled && 'enabled',
          ])}
          onClick={handleClick}
          onMouseDown={onMouseDown}
          ref={ref}
        >
          {prefix && <div className='prefix'>{prefix}</div>}
          {autoSize &&
            <span>{value ?? ''}</span>
          }
          <input
            autoComplete={autoComplete}
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={inputRef}
            required={required}
            step={step}
            tabIndex={tabIndex}
            type={type}
            value={value ?? ''}
            onBlur={handleBlur}
            onChange={onChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onKeyUp={onKeyUp}
          />
          {clearable && !disabled && !readOnly && <div className={'clearable-icon'} onClick={onClear}><ClearIcon /></div>}
          {suffix && <div className={'suffix'}>{suffix}</div>}
        </div>
      }
      {!container && !prefix && !suffix && !clearable && !autoSize &&
        <Tag
          className={classNames([className, 'text-input', 'input-outline'])}
          autoComplete={autoComplete}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref ?? inputRef}
          required={required}
          rows={rows}
          step={step}
          tabIndex={tabIndex}
          type={type}
          value={value ?? ''}
          onBlur={handleBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onMouseDown={onMouseDown}
        />
      }
    </Container>
  );
});

TextInput.propTypes = {
  className: HoopsPropTypes.className,
  autoComplete: HoopsPropTypes.string,
  autoFocus: HoopsPropTypes.bool,
  autoSize: HoopsPropTypes.bool,
  blurOnEnterEscape: HoopsPropTypes.bool,
  clearable: HoopsPropTypes.bool,
  container: HoopsPropTypes.bool,
  disabled: HoopsPropTypes.bool,
  field: HoopsPropTypes.bool,
  inputRef: HoopsPropTypes.object,
  label: HoopsPropTypes.string,
  multiline: HoopsPropTypes.bool,
  rows: HoopsPropTypes.number,
  name: HoopsPropTypes.string,
  prefix: HoopsPropTypes.any,
  readOnly: HoopsPropTypes.bool,
  selectOnFocus: HoopsPropTypes.bool,
  step: HoopsPropTypes.number,
  suffix: HoopsPropTypes.any,
  tabIndex: HoopsPropTypes.number,
  trim: HoopsPropTypes.bool,
  type: HoopsPropTypes.oneOf(['text', 'number', 'tel', 'email', 'password']),
  value: HoopsPropTypes.stringOrNumber,
  required: HoopsPropTypes.bool,
  onBlur: HoopsPropTypes.func,
  onChange: HoopsPropTypes.func,
  onClear: HoopsPropTypes.func,
  onClick: HoopsPropTypes.func,
  onFocus: HoopsPropTypes.func,
  onKeyDown: HoopsPropTypes.func,
  onKeyUp: HoopsPropTypes.func,
  onMouseDown: HoopsPropTypes.func
};
